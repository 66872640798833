<template>
  <div class="home">
    <div class="home-intro">
      <div
        class="container home-intro__wrapper"
        :class="{ 'home-intro__wrapper--auth': userStore.isAuth }"
      >
        <div
          class="home-intro__content"
          :class="{ 'home-intro__content--auth': userStore.isAuth }"
        >
          <div class="home-intro__description">
            <h1 class="home-title">
              <span class="home-title--desktop"
                >Залиште заявку на купівлю автозапчастин вже зараз!</span
              >
              <span class="home-title--mobile">
                {{
                  userStore.isAuth
                    ? "Підберемо автозапчастину для Вас"
                    : "Почніть з вибору автомобіля"
                }}
              </span>
            </h1>
            <p class="home-intro__text">
              <span>
                Наш сервіс дозволяє вам швидко і зручно знайти необхідні
                автозапчастини.</span
              >
              <span>
                Просто заповніть форму, і продавці з усієї України надішлють вам
                свої пропозиції.
              </span>
            </p>
          </div>

          <div v-if="userStore.isAuth" class="home__actions">
            <nuxt-link
              v-for="item in userModeStore.getHomeActionsLink"
              :key="item.id"
              class="home__actions-item"
              :to="item.link"
            >
              <v-icon class="home__actions-icon">{{ item.icon }}</v-icon>
              <span class="home__actions-title">{{ item.name }}</span>
            </nuxt-link>
          </div>

          <div v-else class="home-intro__request">
            <span class="home-intro__request-title"
              >Почніть з вибору автомобіля</span
            >
            <RequestForm v-model="currentCar" :fullForm="false" />
            <v-btn
              class="mt-2"
              size="large"
              color="black"
              block
              @click="confirmForm"
              variant="flat"
            >
              Продовжити
            </v-btn>
          </div>
        </div>
        <div class="home-intro__stats">
          <div class="home-intro__stats-item">
            <span> <VIcon>mdi-account-multiple-outline</VIcon> 5 000+</span>
            <p>Задоволених клієнтів</p>
          </div>
          <div class="home-intro__stats-item">
            <span><VIcon>mdi-cog-outline</VIcon> 10 000+</span>
            <p>Автозапчастин</p>
          </div>
          <div class="home-intro__stats-item">
            <span><VIcon>mdi-store-outline</VIcon>700+</span>
            <p>Перевірених продавців</p>
          </div>
        </div>
      </div>
    </div>

    <div class="container home-service">
      <h2 class="home-section__title">Переваги сервісу</h2>

      <div class="home-service__content">
        <div class="home-service__icon">
          <img src="@/public/img/preview.webp" alt="" data-not-lazy />
        </div>

        <div class="home-service__wrapper">
          <div class="home-service__item">
            <div class="home-service__item-header">
              <IconServiceQuestion class="home-service__item-icon" />
              <h3 class="home-service__item-title">
                Більше 10 000 пропозицій щодня
              </h3>
            </div>
            <p class="home-service__item-text">
              Наші продавці пропонують величезний вибір автозапчастин, тож ви
              завжди знайдете те, що вам потрібно. Щодня доступно більше 10 000
              пропозицій на різні деталі.
            </p>
          </div>

          <div class="home-service__item">
            <div class="home-service__item-header">
              <IconServiceSettings class="home-service__item-icon" />
              <h3 class="home-service__item-title">
                Обробка запитів протягом 15 хвилин
              </h3>
            </div>
            <p class="home-service__item-text">
              Вам не потрібно чекати довго. Отримуйте перші пропозиції від
              продавців вже через 15 хвилин після створення запиту.
            </p>
          </div>

          <div class="home-service__item">
            <div class="home-service__item-header">
              <IconServicePeople class="home-service__item-icon" />
              <h3 class="home-service__item-title">
                Понад 5 000 перевірених продавців
              </h3>
            </div>
            <p class="home-service__item-text">
              На платформі зареєстровано більше 5 000 надійних продавців з усієї
              країни, які готові запропонувати найкращі умови та ціни.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="container home-work">
      <h2 class="home-section__title">Як це працює</h2>

      <div class="home-work__wrapper">
        <div class="home-work__item">
          <IconHowWorkTable />
          <h3 class="home-work__title">Створіть заявку</h3>
          <div class="home-work__text">
            Легко і швидко створюйте запити на потрібні запчастини. Заповніть
            просту форму з інформацією про авто і деталі, які вам потрібні, та
            отримайте пропозиції від перевірених продавців.
          </div>
        </div>

        <div class="home-work__item">
          <IconHowWorkPc />
          <h3 class="home-work__title">Отримайте пропозиції від продавців</h3>
          <div class="home-work__text">
            Продавці зі всієї України зможуть переглянути ваш запит і надіслати
            свої пропозиції. Ви побачите ціни, умови доставки та гарантії на
            деталі від різних постачальників.
          </div>
        </div>

        <div class="home-work__item">
          <IconHowWorkPaper />
          <h3 class="home-work__title">Оберіть найкраще та укладіть угоду</h3>
          <div class="home-work__text">
            Порівняйте пропозиції та оберіть найвигіднішу. Оформіть покупку
            прямо на платформі CarPot з безпечними способами оплати та доставкою
            до вашого міста.
          </div>
        </div>
      </div>
    </div>

    <div class="container home-rubrics">
      <h2 class="home-section__title">Знайти автозапчастину по марці авто</h2>

      <div class="home-rubrics__wrapper home-rubrics__wrapper--4">
        <nuxt-link
          v-for="car in cars"
          :key="car.id"
          class="home-rubrics__item home-rubrics__item--4"
          :to="car.link"
          >{{ car.title }}</nuxt-link
        >
      </div>
    </div>

    <div class="container home-rubrics">
      <h2 class="home-section__title">Рубрики автозапчастин</h2>

      <div class="home-rubrics__wrapper">
        <nuxt-link
          v-for="rubric in rubrics"
          :key="rubric.id"
          class="home-rubrics__item"
          :to="rubric.link"
          >{{ rubric.title }}</nuxt-link
        >
      </div>
    </div>

    <div class="home-choose">
      <div class="container home-choose__wrapper">
        <div class="home-choose__info">
          <h3 class="home-choose__info-title">Чому обирають CarPot?</h3>
          <p class="home-choose__info-text">
            Ми пропонуємо швидкі відповіді та надійних продавців, щоб зробити
            ваші покупки максимально вигідними та зручними.
          </p>

          <div class="home-choose__item">
            <IconSpeed class="home-choose__item-icon" />
            <div class="home-choose__item-wrapper">
              <div class="home-choose__item-title">Швидкість</div>
              <div class="home-choose__item-text">
                Отримуйте пропозиції від продавців моментально, економлячи час
                на пошук.
              </div>
            </div>
          </div>

          <div class="home-choose__item">
            <IconWrench class="home-choose__item-icon" />
            <div class="home-choose__item-wrapper">
              <div class="home-choose__item-title">Зручність</div>
              <div class="home-choose__item-text">
                Інтуїтивно зрозумілий інтерфейс дозволяє легко знайти необхідні
                запчастини.
              </div>
            </div>
          </div>

          <div class="home-choose__item">
            <IconSecurity class="home-choose__item-icon" />
            <div class="home-choose__item-wrapper">
              <div class="home-choose__item-title">Надійність</div>
              <div class="home-choose__item-text">
                Співпрацюємо тільки з перевіреними постачальниками для вашої
                впевненості.
              </div>
            </div>
          </div>
        </div>
        <div class="home-choose__faq">
          <v-expansion-panels
            class="home-choose__faq-panel"
            variant="accordion"
            v-model="expansion"
          >
            <v-expansion-panel
              value="first"
              text="Просто зареєструйтесь, залиште заявку з описом необхідної деталі, і наші продавці відгукнуться з пропозиціями. Ви можете порівняти ціни та умови, вибрати найкращий варіант та оформити замовлення."
              title="Як користуватись сервісом"
            ></v-expansion-panel>
            <v-expansion-panel
              value="second"
              text="Просто зареєструйтесь, залиште заявку з описом необхідної деталі, і наші продавці відгукнуться з пропозиціями. Ви можете порівняти ціни та умови, вибрати найкращий варіант та оформити замовлення."
              title="Обліковий запис"
            ></v-expansion-panel>
            <v-expansion-panel
              value="third"
              text="Просто зареєструйтесь, залиште заявку з описом необхідної деталі, і наші продавці відгукнуться з пропозиціями. Ви можете порівняти ціни та умови, вибрати найкращий варіант та оформити замовлення."
              title="Заявки та замовлення"
            ></v-expansion-panel>
            <v-expansion-panel
              value="fourth"
              text="Просто зареєструйтесь, залиште заявку з описом необхідної деталі, і наші продавці відгукнуться з пропозиціями. Ви можете порівняти ціни та умови, вибрати найкращий варіант та оформити замовлення."
              title="Підтримка клієнтів"
            ></v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script setup lang="ts">
import { useUserStore } from "@/stores/useUserStore";
import { useUserModeStore } from "@/stores/useUserModeStore";
import RequestForm from "@/components/RequestForm.vue";
import IconServiceQuestion from "~/components/Icons/IconServiceQuestion.vue";
import IconServiceSettings from "~/components/Icons/IconServiceSettings.vue";
import IconServicePeople from "~/components/Icons/IconServicePeople.vue";
import IconHowWorkTable from "~/components/Icons/IconHowWorkTable.vue";
import IconHowWorkPc from "~/components/Icons/IconHowWorkPc.vue";
import IconHowWorkPaper from "~/components/Icons/IconHowWorkPaper.vue";
import IconSpeed from "@/components/Icons/IconSpeed.vue";
import IconWrench from "@/components/Icons/IconWrench.vue";
import IconSecurity from "@/components/Icons/IconSecurity.vue";
import Footer from "~/components/Layout/Footer.vue";

const userStore = useUserStore();
const userModeStore = useUserModeStore();

const currentCar = ref({
  car_brand_id: null,
  car_model_id: null,
  year: null,
});

const expansion = ref(["first"]);

const confirmForm = () => {
  localStorage.setItem("currentCar", JSON.stringify(currentCar.value));
  navigateTo("/login");
};

const cars = computed(() => [
  {
    id: 1,
    title: "Acura",
    link: "/",
  },
  {
    id: 2,
    title: "Alfa Romeo",
    link: "/",
  },
  {
    id: 3,
    title: "Audi",
    link: "/",
  },
  {
    id: 4,
    title: "BMW",
    link: "/",
  },
  {
    id: 5,
    title: "Chevrolet",
    link: "/",
  },
  {
    id: 6,
    title: "Citroen",
    link: "/",
  },
  {
    id: 7,
    title: "Daewoo",
    link: "/",
  },
  {
    id: 8,
    title: "Daihatsu",
    link: "/",
  },
  {
    id: 9,
    title: "Dodge",
    link: "/",
  },
  {
    id: 10,
    title: "Fiat",
    link: "/",
  },
  {
    id: 11,
    title: "Ford",
    link: "/",
  },
  {
    id: 12,
    title: "Geely",
    link: "/",
  },
  {
    id: 13,
    title: "Great Wall",
    link: "/",
  },
  {
    id: 14,
    title: "Honda",
    link: "/",
  },
  {
    id: 15,
    title: "Hyundai",
    link: "/",
  },
  {
    id: 16,
    title: "Infiniti",
    link: "/",
  },
  {
    id: 17,
    title: "Isuzu",
    link: "/",
  },
  {
    id: 18,
    title: "Iveco",
    link: "/",
  },
  {
    id: 19,
    title: "Jaguar",
    link: "/",
  },
  {
    id: 20,
    title: "Jeep",
    link: "/",
  },
  {
    id: 21,
    title: "Kia",
    link: "/",
  },
  {
    id: 22,
    title: "Land Rover",
    link: "/",
  },
  {
    id: 23,
    title: "Lexus",
    link: "/",
  },
  {
    id: 24,
    title: "Mazda",
    link: "/",
  },
  {
    id: 25,
    title: "Mercedes-Benz",
    link: "/",
  },
  {
    id: 26,
    title: "Mini",
    link: "/",
  },
  {
    id: 27,
    title: "Mitsubishi",
    link: "/",
  },
  {
    id: 28,
    title: "Nissan",
    link: "/",
  },
  {
    id: 29,
    title: "Opel",
    link: "/",
  },
  {
    id: 30,
    title: "Peugeot",
    link: "/",
  },
  {
    id: 31,
    title: "Porsche",
    link: "/",
  },
  {
    id: 32,
    title: "Renault",
    link: "/",
  },
  {
    id: 33,
    title: "Rover",
    link: "/",
  },
  {
    id: 34,
    title: "Saab",
    link: "/",
  },
  {
    id: 35,
    title: "Seat",
    link: "/",
  },
  {
    id: 36,
    title: "Skoda",
    link: "/",
  },
  {
    id: 37,
    title: "Smart",
    link: "/",
  },
  {
    id: 38,
    title: "SsangYong",
    link: "/",
  },
  {
    id: 39,
    title: "Subaru",
    link: "/",
  },
  {
    id: 40,
    title: "Suzuki",
    link: "/",
  },
  {
    id: 41,
    title: "Toyota",
    link: "/",
  },
  {
    id: 42,
    title: "Volkswagen",
    link: "/",
  },
  {
    id: 43,
    title: "Volvo",
    link: "/",
  },
  {
    id: 44,
    title: "ВАЗ",
    link: "/",
  },
  {
    id: 45,
    title: "ГАЗ",
    link: "/",
  },
  {
    id: 46,
    title: "ЗАЗ",
    link: "/",
  },
  {
    id: 47,
    title: "ЗІЛ",
    link: "/",
  },
]);

const rubrics = computed(() => [
  {
    id: 1,
    title: "Масла і технічні рідини",
    link: "/",
  },
  {
    id: 7,
    title: "Масла і технічні рідини",
    link: "/",
  },
  {
    id: 13,
    title: "Масла і технічні рідини",
    link: "/",
  },
  {
    id: 2,
    title: "Фільтри",
    link: "/",
  },
  {
    id: 8,
    title: "Фільтри",
    link: "/",
  },
  {
    id: 14,
    title: "Фільтри",
    link: "/",
  },
  {
    id: 3,
    title: "Гальмівна система",
    link: "/",
  },
  {
    id: 9,
    title: "Гальмівна система",
    link: "/",
  },
  {
    id: 15,
    title: "Гальмівна система",
    link: "/",
  },
  {
    id: 4,
    title: "Підвіска та рульове управління",
    link: "/",
  },
  {
    id: 10,
    title: "Підвіска та рульове управління",
    link: "/",
  },
  {
    id: 16,
    title: "Підвіска та рульове управління",
    link: "/",
  },
  {
    id: 5,
    title: "Деталі двигуна",
    link: "/",
  },
  {
    id: 17,
    title: "Деталі двигуна",
    link: "/",
  },
  {
    id: 11,
    title: "Деталі двигуна",
    link: "/",
  },
  {
    id: 6,
    title: "Трансмісія та щеплення",
    link: "/",
  },
  {
    id: 12,
    title: "Трансмісія та щеплення",
    link: "/",
  },
  {
    id: 18,
    title: "Трансмісія та щеплення",
    link: "/",
  },
]);
</script>

<style scoped lang="scss">
.home {
  @media (max-width: 960px) {
    background: var(--color-main-blobe);
  }

  &-choose__faq {
    max-width: 540px;
    padding-top: 28px;

    @media (max-width: 960px) {
      padding-top: 0;
    }
  }

  &-choose__faq-panel {
    border-top: 5px solid var(--color-main-success);
    border-radius: 8px;

    &:deep(.v-expansion-panel-title) {
      color: var(--color-primary-black);
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
    }

    &:deep(.v-expansion-panel-text__wrapper) {
      color: #161c2d;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }

  &-choose__info {
    max-width: 579px;
  }
  &-choose__item-icon {
    width: 32px;
    height: 32px;
  }
  &-choose__info-title {
    color: var(--color-main-white);
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 24px;

    @media (max-width: 600px) {
      font-size: 28px;
      font-weight: 700;
      line-height: 120%;
    }
  }

  &-choose__info-text {
    color: var(--color-main-white);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    opacity: 0.65;
    max-width: 515px;
    margin-bottom: 40px;

    @media (max-width: 600px) {
      font-size: 16px;
      font-weight: 600;
      line-height: 150%;
    }
  }

  &-choose__item {
    display: flex;
    gap: 24px;
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }

    @media (max-width: 600px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &-choose__item-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &-choose__item-title {
    color: var(--color-main-white);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;

    @media (max-width: 600px) {
      text-align: center;
    }
  }

  &-choose__item-text {
    color: var(--color-main-white);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    opacity: 0.7;

    @media (max-width: 600px) {
      text-align: center;
    }
  }

  &-choose__wrapper {
    display: flex;
    gap: 40px;
    justify-content: space-between;

    @media (max-width: 960px) {
      flex-direction: column-reverse;
      align-items: center;
    }

    @media (max-width: 600px) {
      gap: 24px;
    }
  }

  &-choose {
    padding: 91px 0;
    background: var(--color-main-dark);

    @media (max-width: 960px) {
      padding: 40px 0;
    }

    @media (max-width: 600px) {
      padding: 24px 0;
    }
  }

  &-rubrics__item {
    display: block;
    color: #005af0;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    max-width: calc(100% / 3 - 16px);
    width: 100%;

    @media (max-width: 960px) {
      font-size: 16px;
      font-weight: 600;
      line-height: 150%;
    }

    @media (max-width: 600px) {
      max-width: 100%;
    }

    &--4 {
      max-width: calc(100% / 4 - 20px);
    }
  }

  &-rubrics__wrapper {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px 24px;

    @media (max-width: 600px) {
      flex-direction: column;
    }
  }

  &-rubrics {
    margin: 80px auto;

    @media (max-width: 960px) {
      margin: 40px auto;
    }
  }

  &-work {
    margin-top: 80px;

    @media (max-width: 960px) {
      margin-top: 40px;
    }
  }

  &-work__wrapper {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    margin-top: 40px;

    @media (max-width: 600px) {
      flex-direction: column;
    }
  }

  &-work__item {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px;
  }

  &-work__title {
    margin-top: 8px;
    color: var(--color-primary-black);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;

    @media (max-width: 960px) {
      font-size: 16px;
      font-weight: 600;
      line-height: 150%;
    }
  }

  &-work__text {
    color: var(--color-primary-black);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    opacity: 0.7;
  }

  &-section__title {
    color: var(--color-main-dark);
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;

    @media (max-width: 600px) {
      font-size: 20px;
      font-weight: 600;
      line-height: 150%;
    }
  }

  &-service {
    margin-top: 40px;
  }

  &-service__content {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 45px;

    @media (max-width: 960px) {
      flex-direction: column;
      gap: 40px;
    }
  }

  &-service__icon {
    max-width: 635px;
    width: 100%;

    img {
      width: 100%;
    }
  }

  &-service__wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex-shrink: 0;
    max-width: 486px;

    @media (max-width: 960px) {
      max-width: 100%;
    }
  }

  &-service__item {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 4px;
    background: var(--color-main-white);
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2);

    @media (max-width: 600px) {
      padding: 12px;
    }
  }

  &-service__item-header {
    display: flex;
    align-items: center;
    gap: 16px;

    @media (max-width: 600px) {
      gap: 12px;
    }
  }

  &-service__item-icon {
    flex-shrink: 0;

    @media (max-width: 600px) {
      width: 24px;
      height: 24px;
    }
  }

  &-service__item-title {
    color: var(--color-primary-black);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;

    @media (max-width: 600px) {
      font-size: 16px;
      font-weight: 600;
      line-height: 150%;
    }
  }

  &-service__item-text {
    color: var(--color-primary-black);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    @media (max-width: 600px) {
      font-size: 14px;
      font-weight: 400;
      line-height: 150%;
    }
  }

  &-intro {
    position: relative;
    background: url("/img/intro.webp") no-repeat center center;

    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.95) 7.27%,
        rgba(0, 0, 0, 0.8) 32.09%,
        rgba(0, 0, 0, 0.65) 69.94%,
        rgba(0, 0, 0, 0.4) 94.43%
      );
      z-index: 1;
    }
  }

  &-intro__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;

    &--auth {
      flex-direction: column;
      text-align: center;
      gap: 60px;
    }

    @media (max-width: 600px) {
      justify-content: center;
    }
  }

  &-intro__description {
    display: flex;
    flex-direction: column;
    gap: 40px;
    color: var(--color-main-white);

    @media (max-width: 960px) {
      text-align: center;
      gap: 24px;
    }
  }

  &-intro__text {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;

    @media (max-width: 960px) {
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;
      display: flex;
      flex-direction: column;
    }

    @media (max-width: 600px) {
      display: none;
    }
  }

  &-title {
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    @media (max-width: 960px) {
      font-size: 32px;
      font-weight: 500;
      line-height: 120%;
    }

    &--desktop {
      @media (max-width: 600px) {
        display: none;
      }
    }

    &--mobile {
      display: none;
      @media (max-width: 600px) {
        display: block;
        font-size: 20px;
        font-weight: 600;
        line-height: 150%;
        max-width: 306px;
      }
    }
  }

  &-intro__stats {
    display: flex;
    justify-content: center;
    padding: 60px 0 0;
    gap: 40px;
    z-index: 2;
    color: var(--color-main-white);

    @media (max-width: 960px) {
      position: absolute;
      bottom: -268px;
      color: var(--color-main-dark);
      width: calc(100% - 80px);
      padding: 0;
      left: 40px;
      justify-content: space-between;
    }

    @media (max-width: 600px) {
      bottom: -305px;
      width: calc(100% - 48px);
      left: 24px;
      gap: 16px;
    }
  }

  &-intro__stats-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 33px;

    @media (max-width: 600px) {
      gap: 16px;
    }

    span {
      font-size: 40px;
      font-weight: 600;
      line-height: normal;

      @media (max-width: 960px) {
        font-size: 32px;
        font-weight: 500;
        line-height: 120%;
      }

      @media (max-width: 600px) {
        font-size: 16px;
        font-weight: 600;
        line-height: 150%;
      }
    }

    p {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;

      @media (max-width: 960px) {
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
      }

      @media (max-width: 600px) {
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
        text-align: center;
      }
    }
  }

  &-intro__wrapper {
    position: relative;
    z-index: 2;
    padding: 60px 24px;

    &--auth {
      padding: 100px 0;

      @media (max-width: 960px) {
        padding: 50px 0 !important;
        margin-bottom: 175px !important;
      }

      @media (max-width: 600px) {
        padding: 24px 0 152px !important;
        margin-bottom: 230px !important;
      }

      .home-intro__stats {
        @media (max-width: 960px) {
          bottom: -135px;
        }

        @media (max-width: 600px) {
          bottom: -195px;
        }
      }
    }

    @media (max-width: 960px) {
      padding: 40px;
      margin-bottom: 308px;
      padding-bottom: 300px;
    }

    @media (max-width: 600px) {
      padding: 24px;
      margin-bottom: 335px;
      padding-bottom: 120px;
    }
  }

  &-intro__request {
    width: 505px;
    background-color: #f5f5f5;
    flex-shrink: 0;
    padding: 24px;
    border-radius: 8px;
    background: var(--color-main-white);
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

    @media (max-width: 960px) {
      position: absolute;
      bottom: -145px;
      width: calc(100% - 80px);
      left: 40px;
    }

    @media (max-width: 600px) {
      bottom: -200px;
      width: calc(100% - 48px);
      left: 24px;
    }
  }

  &-intro__request-title {
    display: block;
    text-align: center;
    margin-bottom: 32px;
    color: var(--color-primary-black);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;

    @media (max-width: 600px) {
      display: none;
    }
  }

  &__actions {
    display: flex;
    padding: 18px;
    gap: 12px;
    background: var(--color-main-white);
    border-radius: 8px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

    @media (max-width: 960px) {
      gap: 10px;
      padding: 16px;
    }

    @media (max-width: 600px) {
      position: absolute;
      top: 100px;
    }

    @media (max-width: 600px) {
      flex-wrap: wrap;
      gap: 0;
      width: 327px;
      height: 221px;
      padding: 0;
    }
  }

  &__actions-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 132px;
    height: 86px;
    color: var(--color-main-dark);

    @media (max-width: 600px) {
      width: 50%;
      height: 50%;

      &:nth-child(1) {
        border-right: 1px solid var(--color-border-default);
        border-bottom: 1px solid var(--color-border-default);
      }

      &:nth-child(2) {
        border-left: 1px solid var(--color-border-default);
        border-bottom: 1px solid var(--color-border-default);
      }

      &:nth-child(3) {
        border-right: 1px solid var(--color-border-default);
        border-top: 1px solid var(--color-border-default);
      }

      &:nth-child(4) {
        border-left: 1px solid var(--color-border-default);
        border-top: 1px solid var(--color-border-default);
      }
    }
  }

  &__actions-icon {
    font-size: 32px;
  }

  &__actions-title {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.014px;
  }
}
</style>
